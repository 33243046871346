@font-face {
    font-family: 'InterRegular';
    src: url('assets/fonts/InterRegular.eot');
    src: url('assets/fonts/InterRegular.eot') format('embedded-opentype'),
        url('assets/fonts/InterRegular.woff2') format('woff2'),
        url('assets/fonts/InterRegular.woff') format('woff'),
        url('assets/fonts/InterRegular.ttf') format('truetype'),
        url('assets/fonts/InterRegular.svg#InterRegular') format('svg');
}

@font-face {
    font-family: avenir;
    font-style: normal;
    font-weight: 400;
    src: local('Avenir'), url(https://fonts.cdnfonts.com/s/71748/avenir_roman_12.woff) format('woff')
}