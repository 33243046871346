// @import '@angular/material/prebuilt-themes/indigo-pink.css';
* {
  font-family: "InterRegular" !important;
  // font-family: "Nunito Sans", 'avenir' !important;
  letter-spacing: normal !important;
}

.fa {
  font-family: FontAwesome !important;
}

.material-icons {
  font-family: 'Material Icons' !important;
}

.material-symbols-outlined {
  font-family: 'Material Symbols Outlined' !important;
}

.fa-brands,
.fab {
  font-family: "Font Awesome 6 Brands" !important;
}

.h3,
h3 {
  font-size: calc(1.3rem + .6vw) !important;
}

body {
  margin: 0 !important;
  color: #000 !important;
  // font-family: "Nunito Sans", 'avenir' !important;
  line-height: unset !important;
}

.blog_content * {
  font-family: "Nunito Sans", 'avenir' !important;

  b,
  strong {
    color: #0B2265 !important;
  }
}

/* You can add global styles to this file, and also import other style files */

a {
  text-decoration: none;
}

button {
  border: none;
}

.pointer {
  cursor: pointer;
}

// custom scrollbar css

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.custom-modal-lg .modal-dialog {
  max-width: 800px;
  /* Set your desired width */
}

.custom-modal-xl .modal-dialog {
  max-width: 1000px;
  /* Set your desired width */
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: #0b2265 !important;

  &:focus {
    box-shadow: 0 0 0 3px rgb(11 34 101 / 25%) !important;
  }
}

.mat-mdc-text-field-wrapper {
  background: #fff !important;
  border: 1px solid #d2d2d2 !important;
  border-radius: 6px !important;
}

.mdc-text-field--invalid {
  border-color: red !important;
}

.mat-mdc-select-value-text {
  // color: #666666 !important;
  font-size: 14px;
}

input::placeholder,
textarea::placeholder,
.mat-mdc-select-placeholder {
  color: #666666 !important;
  font-size: 14px;
  opacity: 1;
}

.mdc-text-field--filled .mdc-line-ripple::before {
  border: 0;
}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.mdc-text-field--no-label .mat-mdc-form-field-infix {
  padding: 9px 0 !important;
  align-items: center;
  display: flex;
}

.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
}

.mat-mdc-form-field-infix {
  min-height: 50px !important;
  max-height: 50px !important;
}

.mat-mdc-menu-content {
  padding: 0 !important;
}

.mdc-button__label {
  font-family: "InterRegular" !important;
}

.mat_icon {
  order: 2;
  margin-right: -4px !important;
  margin-left: 4px !important;
}


.blog_slider {
  .carousel {
    outline: none;
  }

  .carousel-item {
    height: 100%;
    outline: none;
  }

  .carousel-inner {
    height: 100%;
  }

  .carousel-indicators {
    background: #0000003b;
    margin: 0;
    padding: 5px;
    // width: fit-content;
    // margin: 0 auto;
    // border-radius: 15px;
  }

  .carousel-control-next,
  .carousel-control-prev {
    width: 7%;
    opacity: .9;
    top: 50%;
    background: black;
    // left: 25px;
    transform: translate(0, -50%);
    bottom: unset;
    padding: 15px;

    &:hover {
      opacity: .5;
    }

    @media screen and (max-width:992px) {
      width: 50px;

      // opacity: .09;
      &:hover {
        opacity: .6;
      }
    }
  }
}

.mat-mdc-tab.mdc-tab {
  border: 1px solid #0B2265;
  font-size: 16px;

  &:first-child {
    border-color: gray;
    border-right: none;
  }

  // &:nth-child(2) {
  //   border-right: none;
  // }

  &.mdc-tab--active {
    background-color: #0B2265 !important;

    .mdc-tab__text-label {
      color: #fff !important;
      transition: unset !important;
    }

    // &:focus .mdc-tab__text-label {
    //   color: #fff !important;
    // }
  }


  // &:nth-child(3) {
  //   transform: skew(-20deg);
  // }
}

.mat-mdc-tab-body-content {
  border: 1px solid #0B2265;
  border-top: none;
  border-radius: 0 0 10px 10px;
}


.contact_section {
  .elevate_text {
    font-size: 34px;
    font-weight: 800;

    @media screen and (max-width:768px) {
      font-size: 18px;
    }
  }

  .elevate_description {
    font-size: 16px;
    font-weight: 500;
    color: #6C6C6C;

    @media screen and (max-width:768px) {
      font-size: 14px;
    }
  }

  .contact_btn {
    background-color: #4D50FC;
    padding: 15px 20px;
    color: #fff;
    border-radius: 6px;
    font-weight: 600;
    border: none;
    outline: none;
    letter-spacing: 0.3px !important;
  }
}

.is-invalid {
  border-color: red !important;
}

.mat-drawer-container {
  // z-index: 9999 !important;
  border: 1px solid #E0E0E0;
  position: sticky !important;
  top: 0;
}

.mat-drawer {
  position: fixed !important;
  top: 59px !important;
  // right: 0;
}

// ngb-modal-window {
//   z-index: 9999 !important;
// }

.mat-drawer-backdrop {
  height: 100vh;
  position: fixed !important;
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: transparent !important;
}

.mat-drawer-inner-container {
  width: 260px !important;
  padding: 20px !important;
}

.banner_section {
  height: 100% !important;

  .carousel-inner img {
    height: 100% !important;
    object-fit: cover;
  }

  .carousel-caption {
    padding: 0;
    top: 30px;

    &.odoo_slide {
      // top: 215px;
      // top: 160px;
      top: 150px;

      @media screen and (min-height:700px) {
        top: 210px;
      }
      
      @media screen and (min-width:768px) and (max-width:1400px) and (min-height:700px) {
        top: 210px;
      }

      @media screen and (min-width:1400px) and (max-width:1650px) {
        top: 160px;
      }

      @media screen and (min-width:1650px) {
        top: 180px;
      }

      @media screen and (min-width:1400px) and (min-height:750px) {
        top: 220px;
      }
    }
  }

  .carousel-item,
  .carousel-inner {
    height: 100% !important;
  }

  .carousel-indicators [data-bs-target] {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: 0px 0px 5px -0.5px rgba(0, 0, 0, 0.75);
    border: 0;
  }
}

.skeleton-loader.progress {
  margin: 0 !important;
}

.learn_more_btn {
  position: absolute;
  left: 82px;
  bottom: 205px;
  font-size: 22px;
  font-weight: 600;
  background-color: #62cbc8;
  padding: 20px 26px;
  border: none;
  outline: none;

  @media screen and (max-width: 1500px) {
    left: 70px;
    bottom: 150px;
    font-size: 18px;
    padding: 12px 15px;
  }

  @media screen and (max-width: 1200px) {
    left: 50px;
    bottom: 90px;
    font-size: 16px;
    padding: 12px 15px;
  }

  @media screen and (max-width: 992px) {
    left: 40px;
    bottom: 80px;
    font-size: 12px;
    padding: 8px 10px;
  }

  @media screen and (max-width: 576px) {
    left: 20px;
    bottom: 30px;
    font-size: 12px;
    padding: 8px 10px;
  }
}